import React from "react";
import HeroImage from "./hero.png";
import "./heroImage.css";

function Hero() {
  return (
    <div className="heroImageWrapper">
      <div className="imageWrapper">
        <img src={HeroImage} alt="hero" className="heroImage" />
      </div>

      <div className="heroTextWrapper">
        <p className="heroText">
          DesignUp’s 2020 Circuit is a collection of meet-ups, not-webinars,
          interviews, AMAs and that rare webinar too - all available online, at
          a screen near you. While you stay home - stay informed, stay inspired
          and stay safe. All of this is available for free.{" "}
          <span className="boldFont">
            To attend, you’d need to{" "}
            <a
              href="https://port.designup.io/login"
              className="signUpLinks"
              target="_blank"
              rel="noopener noreferrer"
            >
              sign-in
            </a>{" "}
            or{" "}
            <a
              href="https://port.designup.io/registration"
              className="signUpLinks"
              target="_blank"
              rel="noopener noreferrer"
            >
              sign-up
            </a>{" "}
            via DesignUp Port - never miss another event.
          </span>{" "}
        </p>
      </div>
    </div>
  );
}

export default Hero;
