import React from "react";
// import { motion } from "framer-motion";
import "./footer.css";
import Facebook from "./facebook.svg";
import Insta from "./instagram.svg";
import LinkedIn from "./linkedIn.svg";
import Twitter from "./twitter.svg";
import Youtube from "./youtube.svg";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footerWrapper">
        <div className="followUs">
          <div className="followTitle">
            <p>Follow Us</p>
          </div>
          <div className="allIcons">
            <a
              className="iconContainerFaceBook"
              href="https://facebook.com/designupconf"
            >
              <img src={Facebook} alt="facebook"></img>
            </a>
            <a
              className="iconContainer"
              href="https://instagram.com/designupconf"
            >
              <img src={Insta} alt="facebook"></img>
            </a>
            <a
              className="iconContainer"
              href="https://www.linkedin.com/company/designup-conference/"
            >
              <img src={LinkedIn} alt="facebook"></img>
            </a>
            <a
              className="iconContainer"
              href="https://twitter.com/DesignUpConf"
            >
              <img src={Twitter} alt="facebook"></img>
            </a>
            <a
              className="iconContainer"
              href="https://www.youtube.com/channel/UC6Gm19um-Z6FGphxQjzVvPA"
            >
              <img src={Youtube} alt="facebook"></img>
            </a>
          </div>
        </div>
        <div className="misc">
          <div className="essentials">
            <div className="essentialsTitle">
              <p>And Essentials...</p>
            </div>
            <div className="usefulLinks">
              <div className="extraPadding">
                <a
                  href="https://designup.io/diversity-inclusion/"
                  className="usefulLinks"
                >
                  Diversity & Inclusion
                </a>
              </div>
              <div className="extraPadding">
                <a
                  href="https://designup.io/the-designup-code-of-conduct/"
                  className="usefulLinks"
                >
                  The DesignUp Code of Conduct
                </a>
              </div>
              <div className="extraPadding">
                <a
                  href="https://termsfeed.com/terms-conditions/30b020b60ffce42ebdd3d9b6c30c9580"
                  className="usefulLinks"
                >
                  Terms of Use
                </a>
              </div>
              <div className="extraPadding">
                <a
                  href="https://termsfeed.com/privacy-policy/350c39e0e20affa336118978aa10bc20"
                  className="usefulLinks"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="aboutUs">
          <div className="aboutUsTitle">
            <p>About Us</p>
          </div>

          <div className="aboutUsBody">
            <p>
              DesignUp is a Conference and a rapidly growing community of
              Product Creators, Designers, Makers and Thought-leaders in India
              and SE Asia. DesignUp is community led and volunteer driven. The
              conference attracts User Experience and Interaction Designers,
              Product & Design Leaders, Ux Researchers, UI & Motion Experts,
              Front-End Engineers from over 250 companies at it’s annual
              conference in Bangalore. In it’s 2018 Conference – speakers,
              workshop facilitators and attendees came from Google, AirBnB,
              Uber, Adobe, 3M, SAP, WellsFargo, Atlassian as well as boutique
              studios, startups and global consultancies like DesignIt, KPMG,
              ThoughtWorks, Wipro, Accenture. DesignUp also curates different
              events like workshops, masterclasses and meet-ups across India and
              in Asia. For more details, or to partner/sponsor DesignUp – please
              write to us at team@designup.io
            </p>
          </div>
        </div>
        <div className="otherLinks">
          <div className="theUseful">
            <div className="theUsefulTitle">
              <p>The Useful...</p>
            </div>
            <div className="usefulLinks">
              <div className="extraPadding">
                <a href="https://designup.io/read/" className="usefulLinks">
                  DesignUp Articles/Interviews
                </a>
              </div>
              <div className="extraPadding">
                <a href="https://designup.io/watch/" className="usefulLinks">
                  Watch DesignUp Videos
                </a>
              </div>
              <div className="extraPadding">
                <a href="https://designup.io/partner/" className="usefulLinks">
                  Partner/Sponsor with DesignUp
                </a>
              </div>
              <div className="extraPadding">
                <a
                  href="https://designup.io/the-designup-2018-scholarships-for-storytellers/"
                  className="usefulLinks"
                >
                  DesignUp Scholarships
                </a>
              </div>
              <div className="extraPadding">
                <a
                  href="https://yourstory.com/2016/11/10-things-you-didnt-know-about-designup/"
                  className="usefulLinks"
                >
                  10 Things you didn’t know about DesignUp
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
