import React from "react";

import "./App.css";
import Hero from "./components/hero/heroImage";
import AllPosts from "./components/post/AllPosts";
import Footer from "./components/footer/footer";

function App() {
  return (
    <div className="App">
      <Hero />
      <AllPosts />
      <Footer />
    </div>
  );
}

export default App;
