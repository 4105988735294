import React from "react";
import "./AllPost.css";
import Post from "./post";

function AllPosts() {
  return (
    <div className="allPostsWrapper">
      {/* <p className="AllPostsTitle">Upcoming Events</p> */}

      {/* <Post
        activeTime="true"
        date="May 31"
        day="Sunday"
        timeIST="5:00 - 6:00 pm IST"
        timePST="7:30 - 8:30 pm SGT"
        timeGMT="11:30 am - 12:30 pm GMT"
        webinarName="The Wellbeing 5x5: The social-distancing Mind-Trap?"
        buttonName="View Recording"
        buttonName="Opens Soon"
        buttonState="linkInactive"
        buttonLinkURL="https://port.designup.io"
        description1="The “not-another-webinar” journey continues with another series of fast paced lightning presentations by practitioners and afficionados, artists and designers, makers and thinkers. Join us as in this episode we dive into the valleys of the mind. As a generation of Design makers and thinkers experience their first major economic downturn - and unprecedented lockdown and social-distancing, we explore the scars that it's leaving on our minds, our collective consciousness and ways of dealing with it - as well as pathways to healing."
        description2=""
        speakerActive="true"
        speaker1="To be announced soon..."
        speaker1Description="Founder and Director, Studio D Radiodurans "
        speaker1Link="https://www.linkedin.com/in/janchipchase/"
        speaker2="Anurag Arora."
        speaker2Description="Founder, Terra.do, "
        speaker2Link="https://www.linkedin.com/in/anuragarora29/"
        articleActive="false"
        article1Description1="Blog post: May the 4th be with you"
        articleLink1="https://medium.com/@anshumanb4/may-the-4th-be-with-you-5746057b3f80"
        article1Description2="Part 1: From ‘jugaad’ masks to urban design – how pandemics are shaping society around us."
        articleLink2="https://yourstory.com/2020/05/coronavirus-covid19-design"
        article1Description3="Part 2: Distancing, delivery, dissemination - design panelists share responses to the coronavirus pandemic."
        articleLink3="https://yourstory.com/2020/05/coronavirus-pandemic-design-responses"
        article1Description4="Part 3: Planning for the next four weeks, months, quarters, years: how we can survive and thrive in a world with coronavirus."
        articleLink4="https://yourstory.com/2020/05/coronavirus-covid19-survive-thrive-design"
      /> */}

      {/* completed events */}
      {/* completed events */}

      {/* <hr /> */}
      <p className="AllPostsTitle extraMargin">Completed Events</p>

      <Post
        activeTime="false"
        date="May 30"
        day="Saturday"
        timeIST="11:00 am - 12:00 pm IST"
        timePST="1:30 - 2:30 pm SGT"
        timeGMT="5:30 - 6:30 am GMT"
        webinarName="Remote Research"
        // buttonName="View Recording"
        buttonName="Register"
        buttonState="notActive"
        buttonLinkURL="https://port.designup.io"
        description1="The pandemic with it's lockdown, travel restrictions or social-distancing norms presents newer challenges to User Researchers. Jan Chipchase, founder and Director of Tokyo based Studio Radiodurans (and former Executive Creative Director of Global Insights for Frog) addresses these and more. "
        description2=""
        speakerActive="true"
        speaker1="Jan Chipchase "
        speaker1Description="Founder and Director at Studio D Radiodurans "
        speaker1Link="https://www.linkedin.com/in/janchipchase/"
        // speaker2="Anurag Arora."
        // speaker2Description="Founder, Terra.do, "
        // speaker2Link="https://www.linkedin.com/in/anuragarora29/"
        articleActive="false"
        // article1Description1="Blog post: May the 4th be with you"
        // articleLink1="https://medium.com/@anshumanb4/may-the-4th-be-with-you-5746057b3f80"
        // article1Description2="Part 1: From ‘jugaad’ masks to urban design – how pandemics are shaping society around us."
        // articleLink2="https://yourstory.com/2020/05/coronavirus-covid19-design"
        // article1Description3="Part 2: Distancing, delivery, dissemination - design panelists share responses to the coronavirus pandemic."
        // articleLink3="https://yourstory.com/2020/05/coronavirus-pandemic-design-responses"
        // article1Description4="Part 3: Planning for the next four weeks, months, quarters, years: how we can survive and thrive in a world with coronavirus."
        // articleLink4="https://yourstory.com/2020/05/coronavirus-covid19-survive-thrive-design"
      />

      <Post
        activeTime="false"
        date="May 24"
        day="Sunday"
        timeIST="5:00 - 6:00 pm IST"
        timePST="7:30 - 8:30 pm SGT"
        timeGMT="11:30 am - 12:30 pm GMT"
        webinarName="The Travel 5x5: End of Wanderlust?"
        // buttonName="View Recording"
        buttonName="Register"
        buttonState="notActive"
        buttonLinkURL="https://zoom.us/webinar/register/WN_SmX1eAabQEGPxB3EmPWJfA"
        description1="The “not-another-webinar” journey continues with another series of fast paced lightning presentations by practitioners and afficionados, artists and designers, makers and thinkers. Join us as in this episode we dive into the world of travel and explore what are the changes that await us: will fear permanently damage wanderlust? will virtual worlds start to take over? Will our destinations and pathways to get there change? What awaits product makers, dreamers and designers in this realm? Explore this and more with our 5 speakers from Bangalore and Bangkok, Sydney, Singapore and Delhi."
        description2=""
        speakerActive="true"
        speaker1="Dorothy Di Stefano, "
        speaker1Description="Founder / Director at Molten Immersive Art, "
        speaker1Link="https://www.linkedin.com/in/immersivedigitalexperiencespecialist/"
        speaker2="Srishti Gupta, "
        speaker2Description="Manager - Product Design at MakeMyTrip, "
        speaker2Link="https://www.linkedin.com/in/srishtigupta89/"
        speaker3="Jayanth Sharma, "
        speaker3Description="Chief Executive Officer at Toehold Travel and Photography Pvt. Ltd., "
        speaker3Link="https://www.linkedin.com/in/jayanthsharma/"
        speaker4="Deven Grover, "
        speaker4Description="Senior Product Design Manager at Agoda, "
        speaker4Link="https://www.linkedin.com/in/devengrover/"
        speaker5="Sebastian Mueller, "
        speaker5Description="Co-Founder, MING Labs."
        speaker5Link="https://www.linkedin.com/in/smueller1512/"
        articleActive="false"
        // article1Description1="Blog post: May the 4th be with you"
        // articleLink1="https://medium.com/@anshumanb4/may-the-4th-be-with-you-5746057b3f80"
        // article1Description2="Part 1: From ‘jugaad’ masks to urban design – how pandemics are shaping society around us."
        // articleLink2="https://yourstory.com/2020/05/coronavirus-covid19-design"
        // article1Description3="Part 2: Distancing, delivery, dissemination - design panelists share responses to the coronavirus pandemic."
        // articleLink3="https://yourstory.com/2020/05/coronavirus-pandemic-design-responses"
        // article1Description4="Part 3: Planning for the next four weeks, months, quarters, years: how we can survive and thrive in a world with coronavirus."
        // articleLink4="https://yourstory.com/2020/05/coronavirus-covid19-survive-thrive-design"
      />

      <Post
        activeTime="false"
        date="May 17"
        day="Sunday"
        timeIST="5:00 - 6:00 pm IST"
        timePST="7:30 - 8:30 pm SGT"
        timeGMT="11:30 am - 12:30 pm GMT"
        webinarName="The (Not-so) Secret Life of Designers"
        // buttonName="View Recording"
        // buttonName="Opens Soon"
        buttonName="Register"
        buttonState="notActive"
        buttonLinkURL="https://zoom.us/webinar/register/WN_pGjuPNzCRLK8wrjQld2e4g"
        description1="Dive into the world of two trained designers whose passion and interests have steered them to a different realm, while keeping them rooted in Design. Meet Suresh Venkat - actor, voiceover artist, and known for interviewing the who's-who from the worlds of sports, business and entertainment both on TV and via other screens. This will be an exclusive (and possibly the first) look behind the journey of this amazing host many of us know so well. "
        description2="And meet Anurag Arora - Product Designer at Uber, whose love for whipping up new dishes eventually landed him at Le Cordon Bleu in London - and he is back to tell us about the amazing recipes and the mouthwatering journey. "
        speakerActive="true"
        speaker1="Suresh Venkat, "
        speaker1Description="Journalist/ Content Designer, "
        speaker1Link="https://www.linkedin.com/in/suvenk/"
        speaker2="Anurag Arora, "
        speaker2Description="Senior Product Designer at Uber."
        speaker2Link="https://www.linkedin.com/in/anuragarora29/"
        articleActive="false"
        // article1Description1="Blog post: May the 4th be with you"
        // articleLink1="https://medium.com/@anshumanb4/may-the-4th-be-with-you-5746057b3f80"
        // article1Description2="Part 1: From ‘jugaad’ masks to urban design – how pandemics are shaping society around us."
        // articleLink2="https://yourstory.com/2020/05/coronavirus-covid19-design"
        // article1Description3="Part 2: Distancing, delivery, dissemination - design panelists share responses to the coronavirus pandemic."
        // articleLink3="https://yourstory.com/2020/05/coronavirus-pandemic-design-responses"
        // article1Description4="Part 3: Planning for the next four weeks, months, quarters, years: how we can survive and thrive in a world with coronavirus."
        // articleLink4="https://yourstory.com/2020/05/coronavirus-covid19-survive-thrive-design"
      />

      <Post
        activeTime="false"
        date="May 04"
        day="Monday"
        timeIST="5:00 - 6:00 pm IST"
        timePST="7:30 - 8:30 pm SGT"
        webinarName="May The 4th Be With You"
        buttonName="View Recording"
        // buttonName="Opens Soon"
        buttonState="linkActive"
        // buttonLinkURL="https://port.designup.io"
        buttonLinkURL="https://youtu.be/WzaknrP0OUk"
        description1="This is strictly not a webinar but a rapid round of lightning sharp presentations (inspired by the lightsaber fights) and a peek into the near future and futures far-far-away (actually not that far). Join 4 Gungans from the idyllic planet Naboo and the 4 humanoids from the Skywalkers home planet Tatoonie. "
        description2="Prepare for some opinions, wild guesses, controversies and misfires as our guests look what the future holds - in the next 4 weeks, months, years and more... For this episode we will also have the furry Chewbacca join us. He doesn't have a speaking slot but he's going to be around... Save the date! Limited seats..."
        speakerActive="true"
        speaker1="Alysha Naples, "
        speaker1Description="Coach, Strategist, Chief Experience Officer, Tindrum, "
        speaker1Link="https://twitter.com/alyshanaples"
        speaker2="Anshuman Bapna, "
        speaker2Description="Founder, Terra.do, "
        speaker2Link="https://twitter.com/bapnaa"
        speaker3="Himanshu Khanna, "
        speaker3Description="Founder & CEO, Sparklin, "
        speaker3Link="https://twitter.com/SparklinGuy"
        speaker4="Shaheena Attarwala, "
        speaker4Description="Design Director at Zoomcar, "
        speaker4Link="https://twitter.com/RuthlessUx"
        speaker5="Dharmesh Ba, "
        speaker5Description="Head of research, Setu/D91 Labs, "
        speaker5Link="https://twitter.com/dharmeshba"
        speaker6="Fatema Raja, "
        speaker6Description="UX Lead at Gojek, "
        speaker6Link="https://twitter.com/FatemajRaja"
        speaker7="Yonatan Levy, "
        speaker7Description="VP Product, The Floor, "
        speaker7Link="https://problemhacking.co"
        speaker8="Nikita Sarkar, "
        speaker8Description="User Experience Researcher, Google"
        speaker8Link="https://twitter.com/nikitasarkar108"
        speaker9=""
        speaker9Description=""
        speaker9Link=""
        speaker10=""
        speaker10Description=""
        speaker10Link=""
        articleActive="true"
        article1Description1="Blog post: May the 4th be with you"
        articleLink1="https://medium.com/@anshumanb4/may-the-4th-be-with-you-5746057b3f80"
        article1Description2="Part 1: From ‘jugaad’ masks to urban design – how pandemics are shaping society around us."
        articleLink2="https://yourstory.com/2020/05/coronavirus-covid19-design"
        article1Description3="Part 2: Distancing, delivery, dissemination - design panelists share responses to the coronavirus pandemic."
        articleLink3="https://yourstory.com/2020/05/coronavirus-pandemic-design-responses"
        article1Description4="Part 3: Planning for the next four weeks, months, quarters, years: how we can survive and thrive in a world with coronavirus."
        articleLink4="https://yourstory.com/2020/05/coronavirus-covid19-survive-thrive-design"
      />

      <Post
        activeTime="false"
        date="April 10"
        day="Wednesday"
        timeIST="7:30 pm - 8:30 pm IST"
        timePST="7:00 am - 8:00 am PST"
        webinarName="The Creative Gig vs. Covid19"
        buttonName="See Recording"
        buttonState="notActive"
        buttonLinkURL="https://zoom.us/webinar/register/5015861924535/WN_8kIWJ95WQhmbGiRLIL-wZw"
        description1="With the pandemic - those working in India's creative gig economy - freelancers, contractors, and consultants, are facing unique challenges. 
        This eclectic panel - freelance designers and heads/founders of some of India's most reputed studios come together to talk about what the Pandemic means for this sector and tips on how to cope, and outlast the lockdown and pandemic.. "
        description2=""
        speakerActive="true"
        speaker1="Anthony Lopez, "
        speaker1Description="Founder, Lopez Design (NewDelhi), "
        speaker1Link="https://twitter.com/anthonyhlopez"
        speaker2="Ashwini Deshpande, "
        speaker2Description="Co-founder/Director, Elephant (Pune), "
        speaker2Link="https://twitter.com/ashwinielephant"
        speaker3="Rahul Gonsalves, "
        speaker3Description="Co-Founder/Partner - Obvious (Bangalore), "
        speaker3Link="https://twitter.com/gonsalves_r"
        speaker4="Seema Seth, "
        speaker4Description="Studio Sky, Member ADI BLR Core Group (Bangalore)."
        speaker4Link="https://twitter.com/seemaseth"
        articleActive="true"
        article1Description1="😷Survive, Revive, Thrive - Business tips for designers in the time of COVID-19"
        articleLink1="https://designup.io/survive-revive-and-thrive/"
        article1Description2="Part 1: Assess, Adapt, Accelerate - YourStory"
        articleLink2="https://yourstory.com/2020/04/designer-coronavirus-covid19-adapt"
        article1Description3="Part 2: Productivity to Purpose - YourStory"
        articleLink3="https://yourstory.com/2020/04/designers-productivity-purpose-coronavirus-covid-19"
        article1Description4="Part 3: Attitudes, Agendas and Alliances - YourStory"
        articleLink4="https://yourstory.com/2020/04/designers-coronavirus-covid19-alliances"
        article1Description5=""
        articleLink5=""
      />

      <Post
        activeTime="false"
        date="April 03"
        day="Friday"
        timeIST="9:00 - 10:00 pm IST"
        timePST="8:30 - 9:30 am PST"
        webinarName="The West View: DesignUp Deconstruct Report"
        buttonName="See Recording"
        buttonState="notActive"
        buttonLinkURL=""
        description1="DesignUp launched the Deconstruct Report recently - based on a first of its kind in-depth study and a deep-dive into the state of Design-In-Tech in India and SE Asia. More details on the study and report in the article section below."
        description2="This eclectic panel of Design and Community leaders working in the West discuss their takes (and takeaways) on the report, thoughts on individual careers and the collective direction of the industry. Plus suggestions for the next study. Shiva, former ECD of Ogilvy Digital and DesignUp advisor, would be moderating the panel."
        speakerActive="true"
        speaker1="Jamie Myrold, "
        speaker1Description="VP of Adobe Design, "
        speaker1Link="https://twitter.com/jamiemyrold"
        speaker2="Brenda Sanderson,  "
        speaker2Description="Exec Director of IxDA, "
        speaker2Link="https://twitter.com/brendamontreal"
        speaker3="Abhijit Thosar, "
        speaker3Description="Design Leader, VMWare, "
        speaker3Link="https://twitter.com/athosar"
        speaker4="Surya Vanka, "
        speaker4Description="Founder of Authentic Design, "
        speaker4Link="https://twitter.com/suryavanka"
        speaker5="Payal Arora, "
        speaker5Description="Professor, Erasmus School of Philosophy, "
        speaker5Link="https://twitter.com/3Lmantra"
        speaker6="Anjali Desai, "
        speaker6Description="Product Design Leader, Intuit."
        speaker6Link="https://twitter.com/DesaiDesigner"
        articleActive="true"
        article1Description1="The Deconstruct Report"
        articleLink1="https://designup.io/deconstruct/"
        article1Description2=""
        articleLink2=""
        article1Description3=""
        articleLink3=""
        article1Description4=""
        articleLink4=""
        article1Description5=""
        articleLink5=""
      />

      <Post
        activeTime="false"
        date="March 20"
        day="Friday"
        timeIST="7:30 - 8:30 pm IST"
        timePST="7:00 - 8:00 am PST"
        webinarName="DesignUp Deconstruct Report: Online Launch"
        buttonName=""
        buttonState="notActive"
        buttonLinkURL=""
        description1="We flagged off the Deconstruct Study in mid-2019 and presented the first cut of findings, on stage at DesignUp, last Nov. The idea was to understand our evolving practice, on our home ground, and amplify our unique voices. Adobe has supported this study - the very first deep dive into the state of the industry in India & SE Asia. DesignUp Founder Jay Dutta presents the 6.5 takeaways from the report - and cofounder Narayan Gopalan talks about what next - and how to download your free copy."
        description2=""
        speakerActive="true"
        speaker1="Abhinit Twari, "
        speaker1Description="Head of Design for GoJek, "
        speaker1Link="https://twitter.com/abhinitial"
        speaker2="Daniel Burka, "
        speaker2Description="Head of ResolveToSaveLives, "
        speaker2Link="https://twitter.com/dburka"
        speaker3="Hemant Suthar, "
        speaker3Description="Head of ADI - the Indian Association of Designers, Founder of FractalInk, "
        speaker3Link="https://twitter.com/hsuthar"
        speaker4="Soo Basu, "
        speaker4Description="Design Manager, Microsoft. "
        speaker4Link="https://twitter.com/soosixty"
        articleActive="true"
        article1Description1="The Deconstruct Report"
        articleLink1="https://designup.io/deconstruct/"
        article1Description2=""
        articleLink2=""
        article1Description3=""
        articleLink3=""
        article1Description4=""
        articleLink4=""
        article1Description5=""
        articleLink5=""
      />
    </div>
  );
}

export default AllPosts;
