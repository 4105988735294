import React from "react";
import "./post.css";

function Post(props) {
  return (
    <div className="postWrapper">
      <div
        className={
          props.activeTime === "true" ? "dateTime" : "lowOpacityDateTime "
        }
      >
        <p className="date">{props.date}</p>
        <div className="dayTimeWrapper">
          <p className="dayTime">{props.day}</p>
          <p className="dayTime">{props.timeIST}</p>
          <p className="dayTime">{props.timePST}</p>
          <p className="dayTime">{props.timeGMT}</p>
        </div>
      </div>
      <div className="webinarDescriptionWrapper">
        <div className="titleWrapper">
          <p className="date extraPaddingRight">{props.webinarName}</p>

          <div className="buttonWrapper">
            <a
              href={props.buttonLinkURL}
              target="_blank"
              rel="noopener noreferrer"
              className={
                props.buttonState === "linkActive"
                  ? "buttonLinkActive"
                  : props.buttonState === "linkInactive"
                  ? "buttonLinkInactive"
                  : "notActive"
              }
            >
              {props.buttonName}
            </a>
          </div>
        </div>
        <p className="postDescription">{props.description1}</p>
        <br />
        <p className="postDescription">{props.description2}</p>
        <p
          className={
            props.speakerActive === "true" ? "speakerTitle" : "notActive"
          }
        >
          SPEAKERS/ PANELISTS
        </p>
        <p className="dayTime">
          <span className="boldText">
            <a
              href={props.speaker1Link}
              className="speakerLinks"
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.speaker1}
            </a>
          </span>
          {props.speaker1Description}
          <span className="boldText">
            <a
              href={props.speaker2Link}
              className="speakerLinks"
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.speaker2}
            </a>
          </span>
          {props.speaker2Description}
          <span className="boldText">
            <a
              href={props.speaker3Link}
              className="speakerLinks"
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.speaker3}
            </a>
          </span>
          {props.speaker3Description}
          <span className="boldText">
            <a
              href={props.speaker4Link}
              className="speakerLinks"
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.speaker4}
            </a>
          </span>
          {props.speaker4Description}
          <span className="boldText">
            <a
              href={props.speaker5Link}
              className="speakerLinks"
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.speaker5}
            </a>
          </span>
          {props.speaker5Description}
          <span className="boldText">
            <a
              href={props.speaker6Link}
              className="speakerLinks"
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.speaker6}
            </a>
          </span>
          {props.speaker6Description}
          <span className="boldText">
            <a
              href={props.speaker7Link}
              className="speakerLinks"
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.speaker7}
            </a>
          </span>
          {props.speaker7Description}
          <span className="boldText">
            <a
              href={props.speaker8Link}
              className="speakerLinks"
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.speaker8}
            </a>
          </span>
          {props.speaker8Description}
          <span className="boldText">
            <a
              href={props.speaker9Link}
              className="speakerLinks"
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.speaker9}
            </a>
          </span>
          {props.speaker9Description}
          <span className="boldText">
            <a
              href={props.speaker10Link}
              className="speakerLinks"
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.speaker10}
            </a>
          </span>
          {props.speaker10Description}
        </p>
        <p
          className={
            props.articleActive === "true" ? "speakerTitle" : "notActive"
          }
        >
          Articles and blog posts based on the webinar
        </p>
        <p className="articles">
          <a
            href={props.articleLink1}
            className="articleLinks"
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.article1Description1}
          </a>
        </p>
        <p className="articles">
          <a
            href={props.articleLink2}
            className="articleLinks"
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.article1Description2}
          </a>
        </p>
        <p className="articles">
          <a
            href={props.articleLink3}
            className="articleLinks"
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.article1Description3}
          </a>
        </p>
        <p className="articles">
          <a
            href={props.articleLink4}
            className="articleLinks"
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.article1Description4}
          </a>
        </p>
        <p className="articles">
          <a
            href={props.articleLink5}
            className="articleLinks"
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.article1Description5}
          </a>
        </p>
      </div>
    </div>
  );
}

export default Post;
